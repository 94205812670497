import { WrapperContainer } from "../../App-styled";
import img from "../../assets/products/line.png";
import img1 from "../../assets/solution/1.png";
import img2 from "../../assets/solution/2.png";
import img3 from "../../assets/solution/3.png";
import img4 from "../../assets/solution/4.png";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";

function SolutionComponent() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <h2>
              {t("Solution.0")} <img src={img} alt="photo" />
            </h2>
            <div className={styled.cards}>
              <div className={styled.texts}>
                <h3> {t("Solution.1")} </h3>
                <p>{t("Solution.5")}</p>
              </div>
              <img src={img1} alt="photo" />
            </div>
            <div className={styled.cards}>
              <img src={img2} alt="photo" />
              <div className={styled.texts}>
                <h3> {t("Solution.2")} </h3>
                <p>{t("Solution.6")}</p>
              </div>
            </div>
            <div className={styled.cards}>
              <div className={styled.texts}>
                <h3> {t("Solution.3")} </h3>
                <p>{t("Solution.7")}</p>
              </div>
              <img src={img3} alt="photo" />
            </div>
            <div className={styled.cards}>
              <img src={img4} alt="photo" />
              <div className={styled.texts}>
                <h3> {t("Solution.4")} </h3>
                <p>{t("Solution.8")}</p>
              </div>
            </div>
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default SolutionComponent;
