import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Api_Url } from "../../services/index";
export const GetGalery = createAsyncThunk("Galery/get", async () => {
  return axios.get(`${Api_Url}/galery`).then((res) => res);
});

const GalerySlice = createSlice({
  name: "Galery",
  initialState: {
    getGalery: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
  },
  extraReducers: {
    [GetGalery.pending]: (state, action) => {
      state.getGalery.Loading = true;
    },
    [GetGalery.fulfilled]: (state, action) => {
      state.getGalery.Error = false;
      state.getGalery.Success = true;
      state.getGalery.Loading = false;
      state.getGalery.Data = action.payload;
    },
    [GetGalery.rejected]: (state, action) => {
      state.getGalery.Error = true;
      state.getGalery.Success = false;
      state.getGalery.Loading = false;
      state.getGalery.Data = [];
    },
  },
});

export default GalerySlice.reducer;
