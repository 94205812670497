import { WrapperContainer } from "../../App-styled";
import img1 from "../../assets/our/4.png";
import img2 from "../../assets/our/5.png";
import img3 from "../../assets/our/6.png";
import img4 from "../../assets/our/7.png";
import styled from "./style.module.css";
import line from "../../assets/products/line.png";
import { useTranslation } from "react-i18next";

function CenterComponent() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <h2>
              {t("Center.0")} <img src={line} alt="" />{" "}
            </h2>
            <div className={styled.cards}>
              <div className={styled.texts}>
                <h3 className={styled.one}>1</h3>
                <h2> {t("Center.1")} </h2>
                <p>{t("Solution.5")}</p>
              </div>
              <img className={styled.mmmimg} src={img1} alt="photo" />
            </div>
            <div className={styled.cards}>
              <img className={styled.mmmimg} src={img2} alt="photo" />
              <div className={styled.texts}>
                <h3 className={styled.two}>2</h3>
                <h2> {t("Center.2")} </h2>
                <p>{t("Solution.6")}</p>
              </div>
            </div>
            <div className={styled.cards}>
              <div className={styled.texts}>
                <h3 className={styled.three}>3</h3>
                <h2> {t("Center.3")} </h2>
                <p>{t("Solution.7")}</p>
              </div>
              <img className={styled.mmmimg} src={img3} alt="photo" />
            </div>
            <div className={styled.cards}>
              <img className={styled.mmmimg} src={img4} alt="photo" />
              <div className={styled.texts}>
                <h3 className={styled.four}>4</h3>
                <h2> {t("Center.4")} </h2>
                <p>{t("Solution.8")}</p>
              </div>
            </div>
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default CenterComponent;
