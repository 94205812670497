import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Api_Url } from "../../services/index";
export const RequestPost = createAsyncThunk("Request/post", async (body) => {
  return axios.post(`${Api_Url}/form`, body).then((res) => res);
});
const RequestSlice = createSlice({
  name: "Request",
  initialState: {
    postRequest: {
      Error: false,
      Loading: false,
      Success: false,
    },
  },
  extraReducers: {
    [RequestPost.pending]: (state, action) => {
      state.postRequest.Loading = true;
    },
    [RequestPost.fulfilled]: (state, action) => {
      state.postRequest.Error = false;
      state.postRequest.Success = true;
      state.postRequest.Loading = false;
    },
    [RequestPost.rejected]: (state, action) => {
      state.postRequest.Error = true;
      state.postRequest.Success = false;
      state.postRequest.Loading = false;
    },
  },
});

export default RequestSlice.reducer;
