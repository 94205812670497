import { WrapperContainer } from "../../../App-styled";
import Info from "../../home/info";
import styled from "./style.module.css";
import img from "../../../assets/products/line.png";
import How from "../../home/how";
import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <h2 className={styled.h2}>
            {t("About.0")} <img src={img} alt="photo" />
          </h2>
        </WrapperContainer>
      </div>
      <Info />
      <How />
    </>
  );
}

export default AboutUs;
