import About from "../pages/About";
import Center from "../pages/Center";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import OurProducts from "../pages/OutProducts";
import Partner from "../pages/Partner";
import Solution from "../pages/Solution";
import Work from "../pages/Work";
import MoreProducts from "../pages/moreProducts";

export const DataRouters = [
  {
    id: 1,
    path: "/",
    Element: <Home />,
  },
  {
    id: 2,
    path: "/about",
    Element: <About />,
  },
  {
    id: 3,
    path: "/solution",
    Element: <Solution />,
  },
  {
    id: 4,
    path: "/ourproducts",
    Element: <OurProducts />,
  },
  {
    id: 5,
    path: "/moreproducts",
    Element: <MoreProducts />,
  },
  {
    id: 6,
    path: "/serviescenter",
    Element: <Center />,
  },
  {
    id: 7,
    path: "/ourwork",
    Element: <Work />,
  },
  {
    id: 7,
    path: "/partner",
    Element: <Partner />,
  },
  {
    id: 8,
    path: "/contact",
    Element: <Contact />,
  },
];
