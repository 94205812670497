import React from "react";
import HomeHero from "./homehero";
import Info from "./info";
import How from "./how";
import Products from "./products";
import Request from "./Request";

function HomeComponent() {
  return (
    <>
      <HomeHero />
      <Info />
      <How />
      <Products />
      <Request />
    </>
  );
}

export default HomeComponent;
