import styled from "styled-components";

export const Menu = styled.div`
  display: none;
  background-color: #17365e;
  position: fixed;
  height: 100%;
  padding: 0 20px 0;
  width: 80%;
  top: 0;
  left: ${(props) => (props.modal ? "-800px" : "0px")};
  transition: 0.8s;
  z-index: 1000;
  overflow-y: scroll;
  .xx {
    text-align: end;
    color: white;
    cursor: pointer;
    transition: 0.65s;
    font-size: 30px;
    margin: 3% 0 0 0;
  }
  .place {
    color: white;
    margin: 5% 0 0 0;
  }
  .num {
    margin: 5% 0 0 0;
    color: white;
    display: flex;
    flex-direction: column;
    a {
      text-decoration: none;
      color: white;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 900px) {
    display: block;
  }

  @media screen and (max-width: 450px) {
    width: 91%;
  }
  .textUl {
    list-style: none;
    li {
      margin-top: 3%;
      padding-bottom: 3%;
      transition: 0.65s;
      &:hover {
        transform: scale(1.02);
      }
      a {
        text-decoration: none;
        color: white;
      }
      select {
        border: none;
        background-color: #0f0f11;
        color: white;
      }
    }
    li:nth-child(6) {
      display: flex;
      flex-direction: column;
    }
  }
  .ulIcons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3%;
    width: 40%;
    margin-top: 20%;
    li {
      img {
        width: 30px;
        cursor: pointer;
        height: 30px;
      }
    }
  }
`;
