import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Api_Url } from "../../services/index";
export const GetImages = createAsyncThunk("Images/get", async () => {
  return axios.get(`${Api_Url}/images`).then((res) => res);
});

const ImagesSlice = createSlice({
  name: "Images",
  initialState: {
    getImages: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
  },
  extraReducers: {
    [GetImages.pending]: (state, action) => {
      state.getImages.Loading = true;
    },
    [GetImages.fulfilled]: (state, action) => {
      state.getImages.Error = false;
      state.getImages.Success = true;
      state.getImages.Loading = false;
      state.getImages.Data = action.payload;
    },
    [GetImages.rejected]: (state, action) => {
      state.getImages.Error = true;
      state.getImages.Success = false;
      state.getImages.Loading = false;
      state.getImages.Data = [];
    },
  },
});

export default ImagesSlice.reducer;
