import React, { useState } from "react";
import { WrapperContainer } from "../../App-styled";
import styled from "./style.module.css";
import "./style.css";
import bigLine from "../../assets/products/line.png";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { GetProducts } from "../../redux/store/products";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function MoreComponent() {
  const cookies = new Cookies();
  const [btn, setBtn] = useState(false);
  const data = useSelector((state) => state.products.getProducts.Data.data);
  const ProductsId = () => {
    return cookies.get("ProductId");
  };
  const Lang = cookies.get("i18next");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetProducts());
  }, []);
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="morrr">
        <div className={styled.WrapperBack}>
          <WrapperContainer>
            {data?.map((elem, index) =>
              elem.id == ProductsId() ? (
                <>
                  <div className={styled.Wrapper}>
                    <div className={styled.moreImgs}>
                      <div className="slidee">
                        <Slider {...settings}>
                          {elem.images.map((ele) => (
                            <div className="MegaImg">
                              <img src={ele.image} alt="photo" />
                            </div>
                          ))}
                        </Slider>
                      </div>
                      <div className={styled.miniImgs}>
                        {elem.images.map((ele) => (
                          <img src={ele.image} alt="photo" />
                        ))}
                      </div>
                    </div>
                    <div className={styled.infors}>
                      <div className={styled.header}>
                        <div className={styled.title}>
                          <h2>{elem.title}</h2>
                        </div>
                        <div className={styled.start}>
                          <p>{t("More.0")}</p>
                          <h3>{t("More.1")}</h3>
                          <button>
                            <NavLink to={"/contact"}>{t("Partner.3")}</NavLink>
                          </button>
                        </div>
                      </div>
                      <div className={styled.decription}>
                        <p>
                          {Lang == "ru" ? (
                            <>{elem.description_ru}</>
                          ) : Lang == "en" ? (
                            <>{elem.description_en}</>
                          ) : Lang == "uz" ? (
                            <>{elem.description_uz}</>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null
            )}
            <div className={styled.Cards}>
              <h2>
                {t("More.2")} <img src={bigLine} alt="photo" />
              </h2>
              <Row style={{ margin: 0, padding: 0 }}>
                {data?.map((elem) => (
                  <Col lg={4} md={6} sm={12}>
                    <div className={styled.card}>
                      {elem.images.map((ele) => (
                        <img
                          src={ele.image}
                          className={styled.megaImg}
                          alt="photo"
                        />
                      ))}
                      <div className={styled.texts}>
                        <h3>{elem.title}</h3>
                        <button
                          onClick={() => {
                            cookies.set("ProductId", elem.id);
                            window.location.reload();
                          }}
                        >
                          <span>{t("Our.4")}</span>
                          <p>
                            <i class="bx bx-right-arrow-alt"></i>
                          </p>
                        </button>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </WrapperContainer>
        </div>
      </div>
    </>
  );
}

export default MoreComponent;
