import { useTranslation } from "react-i18next";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RequestPost } from "../../../redux/store/request";
import { useRef } from "react";
import SuccessModal from "./successModal";
import { useState } from "react";

function Request() {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const name = useRef();
  const number = useRef();
  const description = useRef();
  const HandelPost = async (e) => {
    e.preventDefault();
    const body = {
      name: name.current.value,
      phone_number: number.current.value,
      description: description.current.value,
    };
    await dispatch(RequestPost(body));
    handleOpen();
  };
  const Success = useSelector((state) => state.request.postRequest.Success);
  return (
    <div className={styled.WrapperBack}>
      <WrapperContainer>
        <div className={styled.Wrapper}>
          <div className={styled.cart}>
            <iframe
              src="https://yandex.uz/map-widget/v1/?ll=69.218723%2C41.264916&mode=whatshere&utm_source=share&whatshere%5Bpoint%5D=69.218717%2C41.264987&whatshere%5Bzoom%5D=17&z=19"
              width="560"
              height="400"
              frameborder="1"
              allowfullscreen="true"
            ></iframe>
          </div>
          <div className={styled.request}>
            <h2>{t("Request.0")}</h2>
            <p className={styled.subtext}>{t("Request.1")}</p>
            <form onSubmit={HandelPost}>
              <input ref={name} type="text" placeholder={t("Request.2")} />
              <input ref={number} type="number" placeholder={t("Request.3")} />
              <input
                className={styled.dec}
                type="text"
                ref={description}
                placeholder={t("Request.4")}
              />
              <button type="submit">
                <span>{t("Request.5")}</span>{" "}
                <p>
                  <i class="bx bx-right-arrow-alt"></i>
                </p>
              </button>
            </form>
            <div className={styled.info}>
              <div className={styled.tel}>
                <p className={styled.icon}>
                  <i class="bx bxs-phone-call"></i>
                </p>
                <div>
                  {" "}
                  <p>
                    <span>{t("Header.7")}</span>
                    <a href="tel:+998-93-500-55-51">+998-93-500-55-51</a>
                  </p>
                  <p>
                    <span>{t("Header.8")}</span>
                    <a href="tel:+998-33-841-55-55">+998-33-841-55-55</a>
                  </p>
                </div>
              </div>
              <div className={styled.location}>
                <p className={styled.icon}>
                  <i class="bx bxs-location-plus"></i>
                </p>
                <div>
                  <p>{t("Footer.5")}</p>
                </div>
              </div>
            </div>
            <div className={styled.mail}>
              <p className={styled.icon}>
                <i class="bx bx-envelope"></i>
              </p>
              <div>
                <p>info@site.uz</p>
              </div>
            </div>
          </div>
          <SuccessModal
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        </div>
      </WrapperContainer>
    </div>
  );
}

export default Request;
