import { useTranslation } from "react-i18next";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import { NavLink } from "react-router-dom";

function PartnerHero() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <ul>
              <li>
                <h3>100</h3>
                <p>{t("Partner.0")}</p>
              </li>
              <li>
                <h3>5500</h3>
                <p>{t("Partner.1")}</p>
              </li>
              <li>
                <h3>6</h3>
                <p>{t("Partner.2")}</p>
              </li>
            </ul>
            <button>
              <NavLink to={"/contact"}>{t("Partner.3")}</NavLink>
            </button>
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default PartnerHero;
