import { WrapperContainer } from "../../../App-styled";
import img from "../../../assets/products/line.png";
import ceriImg from "../../../assets/our/img.png";
import styled from "./style.module.css";
import Slider from "react-slick";
import uz from "../../../assets/our/uz.png";
import kor from "../../../assets/our/koezinka.png";
import korn from "../../../assets/our/karona.png";
import xz from "../../../assets/our/xz.png";
import bor from "../../../assets/our/bor.png";
import "./sslider.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetImages } from "../../../redux/store/images";
function Our() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "400px",
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
          autoplay: 1,
        },
      },
    ],
  };
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetImages());
  }, []);
  const Data = useSelector((state) => state.images.getImages.Data);
  const Dats = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <>
      <div className={styled.WrapperDivBack}>
        <div className={styled.WrapperBack}>
          <WrapperContainer>
            <div className={styled.Wrapper}>
              <div className={styled.texts}>
                <h2>
                  {t("About.1")}
                  <img src={img} alt="photo" />
                </h2>
                <p>{t("About.2")}</p>
              </div>
              <div className={styled.img}>
                <img src={ceriImg} alt="photo" />
              </div>
            </div>
          </WrapperContainer>
        </div>
        <div className={styled.Galery}>
          <WrapperContainer>
            <h2>
              {t("About.3")} <img src={img} alt="photo" />
            </h2>
            <div className="WrapperHom">
              <Slider {...settings}>
                {Dats.map((elem, index) => (
                  <div className="sliderIte">
                    <div className="card">
                      <img
                        src="https://static.vecteezy.com/system/resources/previews/000/439/863/original/vector-users-icon.jpg"
                        alt="photo"
                      />
                      <div className="texts">
                        <h2>Kimdur Kimdurov</h2>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </WrapperContainer>
        </div>
        <div className={styled.clients}>
          <WrapperContainer>
            <h2>
              {t("About.4")}
              <img src={img} alt="photo" />
            </h2>
            <ul>
              <li>
                <img src={uz} alt="photo" />
              </li>
              <li>
                <img src={kor} alt="photo" />
              </li>
              <li>
                <img src={korn} alt="photo" />
              </li>
              <li>
                <img src={bor} alt="photo" />
              </li>
              <li>
                <img src={xz} alt="photo" />
              </li>
              <li>
                <img src={kor} alt="photo" />
              </li>
            </ul>
          </WrapperContainer>
        </div>
      </div>
    </>
  );
}

export default Our;
