import React from "react";
import { WrapperContainer } from "../../App-styled";
import bigLine from "../../assets/products/line.png";
import { Tabs } from "antd";
import "./tab.css";
import { Col, Row } from "react-grid-system";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetCategories } from "../../redux/store/categories";
import { GetProducts } from "../../redux/store/products";
import { useState } from "react";
import Cookies from "universal-cookie";
function OurProductsComponent() {
  const cookies = new Cookies();
  const [category, setCategory] = useState(0);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetCategories());
    dispatch(GetProducts());
  }, []);
  const Data = useSelector((state) => state.category.getCategories.Data);
  const lang = window.localStorage.getItem("i18nextLng");
  const items = Data?.data?.map((elem, index) => {
    const id = elem.id;
    return {
      label: `${
        lang == "ru"
          ? elem.category_name
          : lang == "uz"
          ? elem.category_name_uz
          : lang == "en"
          ? elem.category_name_en
          : null
      }`,
      key: id,
      children: `${elem.id}`,
    };
  });
  const data = useSelector((state) => state.products.getProducts.Data);
  const HandelChange = (e) => {
    setCategory(e);
  };

  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <h2>
              {t("Our.0")} <img src={bigLine} alt="photo" />
            </h2>
            <Tabs onChange={HandelChange} defaultActiveKey="1" items={items} />
            <div className={styled.Cards}>
              <Row style={{ margin: 0, padding: 0 }}>
                {data?.data?.map((elem, index) =>
                  category == 0 ? (
                    <>
                      <Col lg={4} md={6} sm={12} key={index}>
                        <div className={styled.card}>
                          {elem.images?.map((ele) => (
                            <img src={ele.image} alt="photo" />
                          ))}
                          <div className={styled.texts}>
                            <h3>{elem.title}</h3>
                            <NavLink to={"/moreproducts"}>
                              <button
                                onClick={() =>
                                  cookies.set("ProductId", elem.id)
                                }
                              >
                                <span>{t("Our.4")}</span>
                                <i class="bx bx-right-arrow-alt"></i>
                              </button>
                            </NavLink>
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      {elem.category.id == category ? (
                        <>
                          <Col lg={4} md={6} sm={12} key={index}>
                            <div className={styled.card}>
                              {elem.images?.map((ele) => (
                                <img src={ele.image} alt="photo" />
                              ))}
                              <div className={styled.texts}>
                                <h3>{elem.title}</h3>
                                <NavLink to={"/moreproducts"}>
                                  <button
                                    onClick={() =>
                                      cookies.set("ProductId", elem.id)
                                    }
                                  >
                                    <span>{t("Our.4")}</span>{" "}
                                    <i class="bx bx-right-arrow-alt"></i>
                                  </button>
                                </NavLink>
                              </div>
                            </div>
                          </Col>
                        </>
                      ) : null}
                    </>
                  )
                )}
              </Row>
            </div>
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default OurProductsComponent;
