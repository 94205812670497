import React from "react";
import Request from "../home/Request";

function Contactcomp() {
  return (
    <>
      <Request />
    </>
  );
}

export default Contactcomp;
