import { configureStore } from "@reduxjs/toolkit";
import Request from "./store/request";
import Images from "./store/images";
import Galery from "./store/galery";
import Categories from "./store/categories";
import Products from "./store/products";

export const store = configureStore({
  reducer: {
    request: Request,
    images: Images,
    galery: Galery,
    category: Categories,
    products: Products,
  },
});
