import { WrapperContainer } from "../../App-styled";
import Logo from ".././../assets/header/logo.png";
import Russian from ".././../assets/header/russian.svg";
import Kingdom from ".././../assets/header/kingdom.svg";
import Uzb from ".././../assets/header/uzb.svg";
import styled from "./style.module.css";
import MenuHamburger from "./hamburger";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { NavLink } from "react-router-dom";
const cookies = new Cookies();

function Header() {
  const [modal, setModal] = useState(false);
  const HandelOpen = (e) => {
    setModal(true);
  };
  const HandelClose = (e) => {
    setModal(false);
  };
  const { t, i18n } = useTranslation();

  const HandelUz = (e) => {
    window.localStorage.setItem("i18nextLng", "uz");
    window.location.reload();
  };
  const HandelRu = (e) => {
    window.localStorage.setItem("i18nextLng", "ru");
    window.location.reload();
  };
  const HandelEn = (e) => {
    window.localStorage.setItem("i18nextLng", "en");
    window.location.reload();
  };

  return (
    <>
      <WrapperContainer>
        <div className={styled.header}>
          <NavLink to={"/"}>
            <img src={Logo} alt="photo" />
          </NavLink>
          <ul>
            <li>
              <NavLink to={"/about"}>
                <a href="#">{t("Header.0")}</a>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/solution"}>
                <a href="#">{t("Header.1")}</a>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/ourproducts"}>
                <a href="#">{t("Header.2")}</a>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/serviescenter"}>
                <a href="#">{t("Header.3")}</a>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/ourwork"}>
                <a href="#">{t("Header.4")}</a>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/partner"}>
                <a href="#">{t("Header.5")}</a>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/contact"}>
                <a href="#">{t("Header.6")}</a>
              </NavLink>
            </li>
            <li className={styled.lang} onClick={HandelUz}>
              <img src={Russian} alt="photo" />
            </li>
            <li className={styled.lang} onClick={HandelRu}>
              <img value="ru" src={Uzb} alt="photo" />
            </li>
            <li className={styled.lang} onClick={HandelEn}>
              <img value="en" src={Kingdom} alt="photo" />
            </li>
          </ul>
          <div className={styled.menuIcon}>
            <i onClick={HandelOpen} class="bx bx-menu"></i>
          </div>
        </div>
      </WrapperContainer>
      <MenuHamburger modal={modal} HendelClose={HandelClose} />
    </>
  );
}

export default Header;
