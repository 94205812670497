
import { useEffect } from "react";
import AboutCompoenent from "../../components/About";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AboutCompoenent />
    </>
  );
}

export default About;
