import styled from "./style.module.css";
import sun from "../../../assets/info/sun.png";
import why from "../../../assets/info/why.png";
import check from "../../../assets/info/check.png";
import hand from "../../../assets/info/hand.png";
import medal from "../../../assets/info/medal.png";
import orange from "../../../assets/info/orange.png";
import green from "../../../assets/info/green.png";
import red from "../../../assets/info/red.png";
import blue from "../../../assets/info/blue.png";
import mediaWhy from "../../../assets/info/mediaWhy.png";
import uz from "../../../assets/info/uz.png";
import en from "../../../assets/info/en.png";
import Mediauz from "../../../assets/info/mediaUz.png";
import Mediaen from "../../../assets/info/mediaEn.png";

import { WrapperContainer } from "../../../App-styled";
import { useTranslation } from "react-i18next";

function Info() {
  const { t, i18n } = useTranslation();
  const lang = window.localStorage.getItem("i18nextLng");
  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <div className={styled.why}>
              <p className={styled.subtext}>{t("Info.0")}</p>
              <div className={styled.whyDiv}>
                {lang == "ru" ? (
                  <>
                    {" "}
                    <img className={styled.img} src={why} alt="photo" />
                  </>
                ) : lang == "uz" ? (
                  <>
                    {" "}
                    <img className={styled.img} src={uz} alt="photo" />
                  </>
                ) : lang == "en" ? (
                  <>
                    {" "}
                    <img className={styled.img} src={en} alt="photo" />
                  </>
                ) : null}
                <div className={styled.categories}>
                  <div className={styled.sun}>
                    <img className={styled.headImg} src={sun} alt="photo" />
                    <p>{t("Info.1")}</p>
                    <div className={styled.sunTexts}>
                      <img src={orange} alt="photo" />
                    </div>
                  </div>
                  <div className={styled.check}>
                    <img className={styled.headImg} src={check} alt="photo" />
                    <p>{t("Info.2")}</p>
                    <div className={styled.checkTexts}>
                      <img src={green} alt="photo" />
                    </div>
                  </div>
                  <div className={styled.hand}>
                    <img className={styled.headImg} src={hand} alt="photo" />
                    <p>{t("Info.3")}</p>
                    <div className={styled.handTexts}>
                      <img src={red} alt="photo" />
                    </div>
                  </div>
                  <div className={styled.medal}>
                    <img className={styled.headImg} src={medal} alt="photo" />
                    <p>{t("Info.4")}</p>
                    <div className={styled.medalTexts}>
                      <img src={blue} alt="photo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styled.mediawhy}>
              <div className={styled.mediawhyDiv}>
                {lang == "ru" ? (
                  <>
                    {" "}
                    <img
                      className={styled.mediaimg}
                      src={mediaWhy}
                      alt="photo"
                    />
                  </>
                ) : lang == "uz" ? (
                  <>
                    {" "}
                    <img
                      className={styled.mediaimg}
                      src={Mediauz}
                      alt="photo"
                    />
                  </>
                ) : lang == "en" ? (
                  <>
                    {" "}
                    <img
                      className={styled.mediaimg}
                      src={Mediaen}
                      alt="photo"
                    />
                  </>
                ) : null}
                <div className={styled.mediacategories}>
                  <div className={styled.mediasun}>
                    <img
                      className={styled.mediaheadImg}
                      src={sun}
                      alt="photo"
                    />
                    <p>{t("Info.1")}</p>
                    <div className={styled.mediasunTexts}>
                      <img src={orange} alt="photo" />
                    </div>
                  </div>
                  <div className={styled.mediacheck}>
                    <img
                      className={styled.mediaheadImg}
                      src={check}
                      alt="photo"
                    />
                    <p>{t("Info.2")}</p>
                    <div className={styled.mediacheckTexts}>
                      <img src={green} alt="photo" />
                    </div>
                  </div>
                  <div className={styled.mediahand}>
                    <img
                      className={styled.mediaheadImg}
                      src={hand}
                      alt="photo"
                    />
                    <p>{t("Info.3")}</p>
                    <div className={styled.mediahandTexts}>
                      <img src={red} alt="photo" />
                    </div>
                  </div>
                  <div className={styled.mediamedal}>
                    <img
                      className={styled.mediaheadImg}
                      src={medal}
                      alt="photo"
                    />
                    <p>{t("Info.4")}</p>
                    <div className={styled.mediamedalTexts}>
                      <img src={blue} alt="photo" />
                    </div>
                  </div>
                </div>
              </div>
              <p className={styled.videoSubtextMedia}>{t("Info.5")}</p>
              <p>{t("Info.6")}</p>
              <button className={styled.mediaButton}>
                <span>{t("Info.7")}</span>
                <i class="bx bx-right-arrow-alt"></i>
              </button>
            </div>
            <div className={styled.video}>
              <p className={styled.forMedia}> {t("Info.0")}</p>
              <div className={styled.iframe}>
                <iframe
                  width="866"
                  height="498"
                  src="https://www.youtube.com/embed/9oqejtdJFJw"
                  title="LONGi Hi-MO 6 Series Modules Product Video (Global Version)"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <p className={styled.videoSubtext}>{t("Info.6")}</p>
              <p className={styled.vsub}>{t("Info.5")}</p>
              <button>
                <span>{t("Info.7")}</span> <i class="bx bx-right-arrow-alt"></i>
              </button>
            </div>
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default Info;
