import AboutUs from "./aboutus";
import Our from "./our";

function AboutCompoenent() {
  return (
    <>
      <AboutUs />
      <Our />
    </>
  );
}

export default AboutCompoenent;
