import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import "react-slideshow-image/dist/styles.css";
import "./style.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
function HomeHero() {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="Slide">
        <Slider {...settings}>
          <div className="slide1">
            <WrapperContainer>
              <div className={styled.Wrapper}>
                <h2>{t("HomeHero.0")}</h2>
                <p> {t("HomeHero.1")}</p>
                <button>
                  {" "}
                  <NavLink to={"/contact"}>{t("HomeHero.2")}</NavLink>
                </button>
              </div>
            </WrapperContainer>
          </div>
          <div className="slide2">
            <WrapperContainer>
              <div className={styled.Wrapper1}>
                <h2>{t("HomeHero.3")}</h2>
                <p>{t("HomeHero.4")}</p>
                <button>
                  {" "}
                  <NavLink to={"/contact"}>{t("HomeHero.2")}</NavLink>
                </button>
              </div>
            </WrapperContainer>
          </div>
          <div className="slide3">
            <WrapperContainer>
              <div className={styled.Wrapper2}>
                <h2>{t("HomeHero.5")}</h2>
                <p>{t("HomeHero.6")}</p>
                <button>
                  {" "}
                  <NavLink to={"/contact"}>{t("HomeHero.2")}</NavLink>
                </button>
              </div>
            </WrapperContainer>
          </div>
        </Slider>
      </div>
    </>
  );
}

export default HomeHero;
