import React, { useEffect } from "react";
import MoreComponent from "../../components/more";

function MoreProducts() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MoreComponent />
    </>
  );
}

export default MoreProducts;
