import React from "react";
import Contactcomp from "../../components/Contact";

function Contact() {
  return (
    <>
      <Contactcomp />
    </>
  );
}

export default Contact;
