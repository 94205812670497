import styled from "./style.module.css";
import Logo from "../../assets/footer/logo.png";
import { WrapperContainer } from "../../App-styled";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function Footer() {
  const { t, i18n } = useTranslation("");
  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <img src={Logo} alt="photo" />
            <ul>
              <li>
                <h2>{t("Footer.0")}</h2>
              </li>
              <li>
                <NavLink to={"/about"}>
                  <a href="#">{t("Header.0")}</a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/solution"}>
                  <a href="#">{t("Header.1")}</a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/ourproducts"}>
                  <a href="#">{t("Header.2")}</a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/serviescenter"}>
                  <a href="#">{t("Header.3")}</a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/ourwork"}>
                  <a href="#">{t("Header.4")}</a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/partner"}>
                  <a href="#">{t("Header.5")}</a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/contact"}>
                  <a href="#">{t("Header.6")}</a>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <h2>{t("Footer.1")}</h2>
              </li>
              <li>
                <NavLink to={"/ourproducts"}>
                  <p>{t("Footer.2")}</p>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/ourproducts"}>
                  <p>{t("Footer.8")}</p>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/ourproducts"}>
                  <p>{t("Footer.3")}</p>
                </NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <h2>{t("Footer.4")}</h2>
              </li>
              <li>
                <p>{t("Footer.5")}</p>
              </li>
              <li className={styled.tel}>
                <p>
                  <span>{t("Header.7")}</span>
                  <a href="tel:+998-93-500-55-51">+998-93-500-55-51</a>
                </p>
                <p>
                  <span>{t("Header.8")}</span>
                  <a href="tel:+998-33-841-55-55">+998-33-841-55-55</a>
                </p>
              </li>
              <li>
                <p>info@ies.uz</p>
              </li>
              <li>
                <div className={styled.icons}>
                  <p>
                    <a href="https://instagram.com/solarproducts.uz?igshid=MzRlODBiNWFlZA==">
                      <i class="bx bxl-instagram"></i>
                    </a>
                  </p>
                  <p>
                    <a href="https://t.me/quyosh_panellariToshkent">
                      <i class="bx bxl-telegram"></i>
                    </a>
                  </p>
                  <p>
                    <i class="bx bxl-facebook"></i>
                  </p>
                  <p>
                    <i class="bx bxl-whatsapp"></i>
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className={styled.bottom}>
            <p className={styled.date}>{t("Footer.6")}</p>
            <p className={styled.site}>{t("Footer.7")}</p>
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default Footer;
