import React, { useEffect } from "react";
import SolutionComponent from "../../components/Solution";

function Solution() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SolutionComponent />
    </>
  );
}

export default Solution;
