import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Api_Url } from "../../services/index";
export const GetCategories = createAsyncThunk("Categories/get", async () => {
  return axios.get(`${Api_Url}/category`).then((res) => res);
});

const CategoriesSlice = createSlice({
  name: "Categories",
  initialState: {
    getCategories: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
  },
  extraReducers: {
    [GetCategories.pending]: (state, action) => {
      state.getCategories.Loading = true;
    },
    [GetCategories.fulfilled]: (state, action) => {
      state.getCategories.Error = false;
      state.getCategories.Success = true;
      state.getCategories.Loading = false;
      state.getCategories.Data = action.payload;
    },
    [GetCategories.rejected]: (state, action) => {
      state.getCategories.Error = true;
      state.getCategories.Success = false;
      state.getCategories.Loading = false;
      state.getCategories.Data = [];
    },
  },
});

export default CategoriesSlice.reducer;
