import React from "react";
import { WrapperContainer } from "../../../App-styled";
import styled from "./style.module.css";
import hand from "../../../assets/partner/hand.png";
import pen from "../../../assets/partner/pen.png";
import search from "../../../assets/partner/search.png";
import arrow from "../../../assets/partner/Arrow.png";
import How from "../../home/how";
import { useTranslation } from "react-i18next";
import Request from "../../home/Request";

function HowCan() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <h2>{t("Partner.4")}</h2>
            <div className={styled.cards}>
              <div className={styled.card}>
                <div className={styled.cardHeader}>
                  <h3>01</h3>
                  <img src={search} alt="photo" />
                </div>
                <p>{t("Partner.5")}</p>
              </div>
              <img className={styled.arrow} src={arrow} alt="photo" />
              <div className={styled.card}>
                <div className={styled.cardHeader}>
                  <h3>02</h3>
                  <img src={pen} alt="photo" />
                </div>
                <p>{t("Partner.6")}</p>
              </div>
              <img className={styled.arrow} src={arrow} alt="photo" />
              <div className={styled.card}>
                <div className={styled.cardHeader}>
                  <h3>03</h3>
                  <img src={hand} alt="photo" />
                </div>
                <p>{t("Partner.7")}</p>
              </div>
            </div>

            <h3 className={styled.warring}>{t("Partner.8")}</h3>
            <p className={styled.subtext}>{t("Partner.9")}</p>
          </div>
        </WrapperContainer>
      </div>
      <How />
      <Request />
    </>
  );
}

export default HowCan;
