import { useEffect } from "react";
import WorkComponent from "../../components/Work";

function Work() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <WorkComponent />
    </>
  );
}

export default Work;
