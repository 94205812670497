import { useTranslation } from "react-i18next";
import { Menu } from "./styled-index";
import Russian from "../../../assets/header/russian.svg";
import Kingdom from "../../../assets/header/kingdom.svg";
import Uzb from "../../../assets/header/uzb.svg";
import { NavLink } from "react-router-dom";

function MenuHamburger({ modal, HendelClose }) {
  const { t, i18n } = useTranslation();
  const HandelUz = (e) => {
    window.localStorage.setItem("i18nextLng", "uz");
    window.location.reload();
  };
  const HandelRu = (e) => {
    window.localStorage.setItem("i18nextLng", "ru");
    window.location.reload();
  };
  const HandelEn = (e) => {
    window.localStorage.setItem("i18nextLng", "en");
    window.location.reload();
  };
  return (
    <Menu modal={modal === false}>
      <p className="xx" onClick={HendelClose}>
        <i class="bx bx-x"></i>
      </p>
      <ul className="textUl">
        <li onClick={HendelClose}>
          <NavLink to={"/about"}>
            <a href="#">{t("Header.0")}</a>
          </NavLink>
        </li>
        <li onClick={HendelClose}>
          <NavLink to={"/solution"}>
            <a href="#">{t("Header.1")}</a>
          </NavLink>
        </li>
        <li onClick={HendelClose}>
          <NavLink to={"/ourproducts"}>
            <a href="#">{t("Header.2")}</a>
          </NavLink>
        </li>
        <li onClick={HendelClose}>
          <NavLink to={"/serviescenter"}>
            <a href="#">{t("Header.3")}</a>
          </NavLink>
        </li>
        <li onClick={HendelClose}>
          <NavLink to={"/ourwork"}>
            <a href="#">{t("Header.4")}</a>
          </NavLink>
        </li>
        <li onClick={HendelClose}>
          <NavLink to={"/partner"}>
            <a href="#">{t("Header.5")}</a>
          </NavLink>
        </li>
        <li onClick={HendelClose}>
          <NavLink to={"/contact"}>
            <a href="#">{t("Header.6")}</a>
          </NavLink>
        </li>
      </ul>
      <ul className="ulIcons">
        <li className="lang" onClick={HandelUz}>
          <img src={Russian} alt="photo" />
        </li>
        <li className="lang" onClick={HandelRu}>
          <img value="ru" src={Uzb} alt="photo" />
        </li>
        <li className="lang" onClick={HandelEn}>
          <img value="en" src={Kingdom} alt="photo" />
        </li>
      </ul>
      <p className="place">{t("Footer.5")}</p>
      <p className="num">
        <span>{t("Header.7")}</span>
        <a href="tel:+998-93-500-55-51">+998-93-500-55-51</a>
      </p>
      <p className="num">
        <span>{t("Header.8")}</span>
        <a href="tel:+998-33-841-55-55">+998-33-841-55-55</a>
      </p>
    </Menu>
  );
}

export default MenuHamburger;
