import React from "react";
import { WrapperContainer } from "../../App-styled";
import station from "../../assets/work/statiom.png";
import sun from "../../assets/work/sun.png";
import panel from "../../assets/work/panel.png";
import line from "../../assets/products/line.png";
import vector from "../../assets/work/Vector.png";
import styled from "./style.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetGalery } from "../../redux/store/galery";
function WorkComponent() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetGalery());
  }, []);
  const Data = useSelector((state) => state.galery.getGalery.Data);
  console.log(Data);
  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <div className={styled.Wrapper}>
            <h2>
              {t("Work.0")} <img src={line} alt="photo" />
            </h2>
            {Data?.data?.map((elem, index) => (
              <div className={styled.card}>
                <img className={styled.mainimg} src={elem.image} alt="photo" />
                <div className={styled.texts}>
                  <h3>{elem.title}</h3>
                  <div>
                    <p>
                      <i class="bx bxs-location-plus"></i>
                      <span> {elem.adress}</span>
                    </p>
                    <p>
                      <i class="bx bx-wallet"></i>
                      <span>
                        {" "}
                        {t("Work.3")}: {elem.year} {t("Work.4")}
                      </span>
                    </p>
                    <p>
                      <ion-icon name="thunderstorm-outline"></ion-icon>
                      <span> {elem.energy_month}</span>
                    </p>
                  </div>
                </div>
                <img className={styled.vec} src={vector} alt="photo" />
              </div>
            ))}
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default WorkComponent;
