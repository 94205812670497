import styled from "./style.module.css";
import { WrapperContainer } from "../../../App-styled";
import bigLine from "../../../assets/how/lineBig.png";
import Line from "../../../assets/how/line.png";
import station from "../../../assets/how/station.png";
import { useTranslation } from "react-i18next";

function How() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className={styled.WrapperBack}>
        <WrapperContainer>
          <h2>
            {t("How.0")} <img src={bigLine} alt="photo" />
          </h2>
          <div className={styled.Wrapper}>
            <div className={styled.texts}>
              <ul>
                <li>
                  <p className={styled.one}>1</p>
                  <img className={styled.lineimg} src={Line} alt="photo" />
                  <div>
                    <h3> {t("How.1")} </h3>
                    <p>{t("How.2")}</p>
                  </div>
                </li>
                <li>
                  <p className={styled.two}>2</p>
                  <img className={styled.lineimg} src={Line} alt="photo" />
                  <div>
                    <h3> {t("How.3")} </h3>
                    <p>{t("How.4")}</p>
                  </div>
                </li>
                <li>
                  <p className={styled.three}>3</p>
                  <img className={styled.lineimg} src={Line} alt="photo" />
                  <div>
                    <h3> {t("How.5")} </h3>
                    <p>{t("How.6")}</p>
                  </div>
                </li>
                <li>
                  <p className={styled.four}>4</p>
                  <img className={styled.lineimg} src={Line} alt="photo" />
                  <div>
                    <h3> {t("How.7")} </h3>
                    <p>{t("How.8")}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className={styled.img}>
              <img src={station} alt="photo" />
            </div>
          </div>
        </WrapperContainer>
      </div>
    </>
  );
}

export default How;
