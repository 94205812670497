import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Api_Url } from "../../services/index";
export const GetProducts = createAsyncThunk("Products/get", async () => {
  return axios.get(`${Api_Url}/products`).then((res) => res);
});

const ProductsSlice = createSlice({
  name: "Products",
  initialState: {
    getProducts: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
  },
  extraReducers: {
    [GetProducts.pending]: (state, action) => {
      state.getProducts.Loading = true;
    },
    [GetProducts.fulfilled]: (state, action) => {
      state.getProducts.Error = false;
      state.getProducts.Success = true;
      state.getProducts.Loading = false;
      state.getProducts.Data = action.payload;
    },
    [GetProducts.rejected]: (state, action) => {
      state.getProducts.Error = true;
      state.getProducts.Success = false;
      state.getProducts.Loading = false;
      state.getProducts.Data = [];
    },
  },
});

export default ProductsSlice.reducer;
