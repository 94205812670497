import React from "react";
import PartnerHero from "./partnerHero";
import HowCan from "./howcan";

function PartnerComponent() {
  return (
    <>
      <PartnerHero />
      <HowCan />
    </>
  );
}

export default PartnerComponent;
