import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styled from "./styled.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "3px solid tranparent",
  boxShadow: 10,
};

export default function SuccessModal({ handleClose, handleOpen, open }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styled.Box}>
          <div className={styled.modal}>
            <div className={styled.icon}>
              <i class="bx bx-check bx-tada"></i>
            </div>
            <h2 className={styled.h2Text}>Ваша заявка успешно отправлена</h2>
            <button
              onClick={() => {
                handleClose();
                window.location.reload();
              }}
            >
              OK
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
